import React from "react"
import blogImg from "../img/story_page_new.png"
import NavigationBar from "../components/Navbar"
import StoriesRoll from "../components/StoriesRoll"
import { Helmet } from "react-helmet"
import loadable from "@loadable/component"
const SocialMedia = loadable(() => import("../components/social-media"));
const Footer3 = loadable(() => import("../components/footer3"))
const NewsletterStrip = loadable(() => import("../components/newsletterStrip"))
const CoursesAndBlogs = loadable(() => import("../components/CoursesAndBlogs"))

export default class Stories extends React.Component {
  render() {
    return (
      <div>
        <Helmet
          htmlAttributes={{
            lang: "en",
          }}
        >
          <meta charSet="utf-8" />
          <title>Success Stories</title>
          <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
          <meta name="description" content="We share success stories of programmers working in various tech industries to inspire learners and help beginners prepare for their dream career path." />
        </Helmet>
        <div className=" sm:sticky top-0 z-50">
          <NavigationBar />
        </div>

        <div className="bg-light-white">
          <div className="container mx-auto px-4 md:px-6 lg:px-6 xl:px-8 2xl:px-10 pt-6 md:pt-8 lg:pt-10">
            <div className="md:grid md:grid-cols-12 md:gap-8">
              <div className="sm:text-center md:col-span-7 md:text-left">
                <h1 className="tracking-wider text-xl lg:text-2xl xl:text-3xl text-gray-900">
                  Success is a Journey!
                </h1>
                <p className="tracking-wider text-base xl:text-lg text-gray-600 mt-2 sm:mt-4 sm:max-w-xl">
                  An initiative by EnjoyAlgorithms to share success stories of
                  professionals working in various tech industries. We highlight
                  critical milestones of their career journeys to inspire millions of
                  learners and help beginners prepare for their dream career
                  path.
                </p>
              </div>
              <div className="mt-6 md:mt-0 lg:-mt-4 md:col-span-5 flex justify-center">
                  <img
                    className="object-cover rounded pointer-events-none w-96 h-60 sm:w-112 sm:h-72 md:w-72 md:h-44 lg:w-80 lg:h-52 2xl:w-96 2xl:h-60"
                    src={blogImg}
                    alt="enjoyalgorithms success story"
                  />
              </div>
            </div>
            <div className="">
              <StoriesRoll />
            </div>
            <SocialMedia />
            <div className="mt-6">
              <NewsletterStrip />
            </div>

            <div className="mt-6">
              <CoursesAndBlogs />
            </div>
          </div>

          <div className="mt-12">
            <Footer3 />
          </div>
        </div>
      </div>
    )
  }
}
