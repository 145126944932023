import React from "react"
import PropTypes from "prop-types"
import { Link, graphql, StaticQuery } from "gatsby"

class StoriesRoll extends React.Component {
  render() {
    const { data } = this.props
    const { edges: posts } = data.allMarkdownRemark

    return (
      <div className="relative pt-6 mb-6">
        <div className="grid my-2 gap-8 xl:gap-10 md:grid-cols-2 xl:grid-cols-3">
          {posts &&
            posts.map(({ node: post }, index) => (
              <Link
                to={post.frontmatter.path}
                className=""
                key={post.id}
              >
                <div className="flex flex-col h-full rounded-2xl shadow-md hover:shadow-xl transition duration-150 overflow-hidden">
                  {post.frontmatter.featuredimage ? (
                    <div className="flex justify-center">
                      <img
                        className="object-cover pointer-events-none h-44 w-80 sm:h-72 sm:w-128 md:w-80 md:h-44 lg:w-112 lg:h-64 xl:h-56 xl:w-96 2xl:w-112 2xl:h-64"
                        src={require("../../static/" +
                          post.frontmatter.featuredimage)}
                        alt="Story page cover"
                      />
                    </div>
                  ) : null}
                  <div className="flex flex-col justify-between flex-1 px-6 pt-1 pb-4">
                    <div className="flex-1">
                      <Link
                        to={post.frontmatter.path}
                        className="tracking-wider text-lg xl:text-xl 2xl:text-2xl leading-6 text-gray-900"
                      >
                        {post.frontmatter.title}
                      </Link>

                      <Link to={post.frontmatter.path}>
                        <p className="mt-3 leading-relaxed tracking-wider overflow-hidden text-xs 2xl:text-sm text-gray-600">
                          {post.frontmatter.description}
                        </p>
                      </Link>
                    </div>
                  </div>
                </div>
              </Link>
            ))}
        </div>
      </div>
    )
  }
}

StoriesRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default () => (
  <StaticQuery
    query={graphql`
      query StoriesRollQuery {
        allMarkdownRemark(
          filter: {
            frontmatter: {
              visibility: { eq: true }
              category: { eq: "stories" }
            }
          }
          sort: { order: DESC, fields: frontmatter___date }
        ) {
          edges {
            node {
              excerpt(pruneLength: 250)
              frontmatter {
                path
                date(formatString: "DD MMMM YYYY")
                category
                featuredimage
                title
                description
                visibility
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <StoriesRoll data={data} count={count} />}
  />
)
